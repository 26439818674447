import React from 'react'
import { Cell } from '~styles/common'
import Block from '~components/Block'
import Link from '~components/Link'

const ServicesInPlayBlock = ({ services, ...props }) => {
  const splitIndex = Math.ceil(services.length / 2)
  const servicesSplit = [
    services.slice(0, splitIndex),
    services.slice(splitIndex, services.length)
  ]

  return (
    <Block
      heading="Services in play on this project"
      headingGridColumn={{ sm: 'span 3' }}
      {...props}
    >
      <Cell
        display={{ _: 'none', sm: 'block' }}
        gridColumn={{ sm: 'span 1', lg: 'span 2' }}
      />
      {servicesSplit.map((servicesHalf, i) =>
        <Cell
          key={i}
          display={{ _: 'none', sm: 'block' }}
          gridColumn="span 3"
        >
          {servicesHalf.map(service =>
            <p key={service.id}>
              <Link
                underlined
                to={"/work?service.id="+service.id}
              >
                {service.title}
              </Link>
            </p>
          )}
        </Cell>
      )}
      <Cell display={{ _: 'block', sm: 'none' }}>
        {services.map(service =>
          <p key={service.id}>
            {service.title}
          </p>
        )}
      </Cell>
      <Cell />
    </Block>
  )
}

export default ServicesInPlayBlock