import React from 'react'
import { graphql } from 'gatsby'

import PageLayer from '~components/PageLayer'
import SEO from '~components/SEO'
import Parallax from '~components/Parallax'
import { MastheadCaseStudy } from '~components/Masthead'
import TextBlock from '~components/TextBlock'
import ImageBlock from '~components/ImageBlock'
import SectionBlock from '~components/SectionBlock'
import ServicesInPlayBlock from './components/ServicesInPlayBlock'

import { mapCraft } from './mappings'

const CaseStudiesTemplate = ({ data }) => {
  const { caseStudy } = mapCraft(data.craft)

  const {
    year,
    website,
    externallink,
    client,
    impacts,
    services
  } = caseStudy.workItem

  const masthead = {
    ...caseStudy.masthead,
    heading: caseStudy.title,
    year,
    website,
    externallink,
    client,
    impact: impacts[0],
    service: services[0]
  }

  return (
    <PageLayer level={2} backTo="/work">
      <SEO
        title={caseStudy.title}
        metadata={caseStudy.metadata}
      />
      <Parallax level={2}>
        <MastheadCaseStudy
          {...masthead}
        />
      </Parallax>
      {masthead.image &&
        <ImageBlock
          image={masthead.image}
          fullscreen
        />
      }
      {caseStudy.challenge &&
        <TextBlock
          mobileOpenInitially
          heading="The challenge"
          body={caseStudy.challenge}
          collapseMobile
        />
      }
      {caseStudy.impactOpportunity &&
        <TextBlock
          mobileOpenInitially
          heading="Impact opportunity"
          body={caseStudy.impactOpportunity}
          collapseMobile
        />
      }
      {caseStudy.sections.map((section, i) =>
        <SectionBlock key={i} {...section} />
      )}
      {caseStudy.outcome &&
        <TextBlock
          heading="Outcome"
          body={caseStudy.outcome}
          backgroundColor="sun"
          collapseMobile
          mobileOpenInitially
        />
      }
    </PageLayer>
  )
}

export const query = graphql`
query ($id: Int) {
  craft {
    caseStudy: entry(id: [$id], section: caseStudies) {
      ...caseStudyFields
      ...on Craft_CaseStudies {
        workItem {
          ...workItemFields
        }
        sections {
          ...textBlockFields
          ...htmlBlockFields
          ...quoteBlockFields
          ...processBlockFields
          ...insightBlockFields
          ...imageBlockFields
          ...doubleImageBlockFields
          ...comboBlockFields
          ...showcaseBlockFields
        }
        metadata {
          ...metadataFields
        }
      }
    }
  }
}
`

export default CaseStudiesTemplate
