import { mapSection, staticSuperTable } from '~utils/mapping-filters'

const mapCaseStudy = (caseStudy) => {
  const {
    title,
    challenge,
    impactOpportunity,
    outcome,
    process
  } = caseStudy

  const masthead = staticSuperTable(caseStudy.masthead)
  const metadata = staticSuperTable(caseStudy.metadata)
  const workItem = staticSuperTable(caseStudy.workItem)

  return {
    title,
    masthead: {
      ...masthead,
      image: masthead.image ? masthead.image[0] : null
    },
    workItem: {
      ...workItem,
      client: workItem.client.slice(-1)[0]
    },
    challenge: challenge ? challenge.content : null,
    impactOpportunity: impactOpportunity ? impactOpportunity.content : null,
    outcome: outcome ? outcome.content : null,
    process,
    sections: caseStudy.sections.map(mapSection),
    metadata
  }
}

export const mapCraft = (craft) => {
  return {
    ...craft,
    caseStudy: mapCaseStudy(craft.caseStudy)
  }
}
